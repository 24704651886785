@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.animacion {
  /*position: absolute;*/
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
   100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
   50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.info-sucursal-modulo{
  display: none;
}

.info-cliente-modulo{
  display: none;
}

.mod-view .ant-card *{
  text-align: center !important;
  align-content: center;
  display: flex;
  justify-content: center;
}

.home-comercios .ant-avatar-image{
width: 80px;
height: 80px;
}

.home-comercios .ant-avatar-square{
width: 80px;
height: 80px;
}

.home-sucursales .ant-avatar-image{
width: 200px;
height: 100px;
}

.home-sucursales .ant-avatar-square{
width: 200px;
height: 100px;
}

.home-sucursales .ant-card-body{
  padding:0px;
}

.home-comercios .ant-card-meta-title{
  font-size: 18px;
  margin-top: 25px;
}

.home-sucursales .ant-card-meta-title{
  margin-top: 25px;
}

.ant-notification-close-icon{
  font-size: calc(16px + 1vmin) !important;
}

.anticon-close{
  font-size: calc(22px + 1vmin) !important;
}

.ant-drawer-close-x .anticon-close{
  margin-top: 12vh;
  font-size: calc(35px + 1vmin) !important;
  display: none;
}

a{
  color:black;
}

.commentsRow{
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(228, 228, 228) !important;
}

h1,h2{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: calc(16px + 1vmin);
}

label{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: calc(12px + 1vmin);
  color:black;
}

.footer-bar{
  overflow-y: scroll;
}

.ReactVirtualized__Grid .ReactVirtualized__List{
  overflow-y: hidden !important;
}

.label-small{
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: calc(10px + 1vmin);
  color:black;
}

.label-subtitle-comments{
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: calc(4px + 1vmin) !important;
  color:black;
}

.label-subtitle{
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: calc(9px + 1vmin) !important;
  color:black;
}


.label-info{
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: calc(4px + 1vmin);
  color:black;
}

h3{
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: calc(6px + 1vmin);
  margin-top: 15px;
  margin-bottom: 0px;
}

h4{
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: calc(6px + 1vmin);
  margin-top: 15px;
  margin-bottom: 0px;
}

h5{
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: calc(4px + 1vmin);
  margin-top: 20px;
  margin-bottom: 10px;
}

.publicity{
  display: flex;
  justify-content: start;
  color:white;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.ButtonAtendiendo{
  background: url("/static/media/boton numero_a.8bf1254a.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 300px;
  height: 300px;
  margin: auto;
}

.ButtonLlamar{
  background: url("/static/media/boton numero.f9ec535b.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 300px;
  height: 300px;
  margin: auto;
}

.App-Home {
  background: url(/static/media/inicio.fce13b06.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 92vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-Header{
  background-color: black;
  height: 100px;
}

.App-Header-Rooms{
  background-color: black;
  height: 100px;
  border-bottom: 1px solid rgb(228, 228, 228) !important;
}

.Filters-Container{
  border-right: 1px solid rgb(228, 228, 228) !important;
  border-bottom: 1px solid rgb(228, 228, 228) !important;
  height: 92vh;
  overflow-y: scroll;
}

.App-Container-Rooms{
  background-color: white;
  height: 92vh;
  overflow-y: hidden;
}

.BoxHome{
  background-color: white;
  border-radius: 5px;
  margin-top: 12vh;
  font-size: calc(10px + 2vmin);
  padding: 20px;
}

.byrow{
  display: flex;
  flex-direction: row;
}

.bycols{
  display: flex;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}

.bmimotel:hover{
  background-color: #ff2b9b;
  color:white;
  border: 1px solid #ff2b9b;
}

.bmimotel{
  background-color: #ff2b9b;
  color:white;
  border: 1px solid #ff2b9b;
}

.bmimoteldisabled{
  background-color: rgb(228, 228, 228);
  color:black;
  border: 1px solid rgb(228, 228, 228);
}

.bmimotelpay{
  background-color: green;
  color:white;
  border: 1px solid green;
}

.bmimotelpay:hover{
  background-color: #ff2b9b;
  color:white;
  border: 1px solid #ff2b9b;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: calc(6px + 1vmin);
}

.Rooms-Home{
  height: 100vh;
}

.logoHeader{
  height: 8vh;
}

.TimePickerHeader{
  height: 8vh;
}

.Filter-Container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Container-Room-Type{
  border: 1px solid rgb(228, 228, 228) !important;
  height: 212px;
  margin-bottom: 10px;
}

.ant-carousel .slick-slide {
  text-align: center;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.Images-Slider-Rooms{
  width:100%;
  height: 210.6px;
}

.Images-Slider-Rooms-Modal{
  width:100%;
  height: 280px;
}

.slick-prev {
    left: 10px !important;
    z-index: 100;
}

.slick-next {
    right: 10px !important;
    z-index: 100;
}

.Rooms-Container{
  height: 88vh;
  overflow-y: hidden;
}

.Filters-Button{
  display: none;
}

.ant-modal-body{
  padding: 0px !important;
}

.ant-modal-close-x{
  border:1px solid white;
}

.ant-modal-close-x i{
  color:white;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.information-container-room-modal{
  border:0px solid white !important;
}

/*Media Querys*/
@media (max-width: 768px) {

  .title-seccion{
    margin-bottom: 0px !important;
    margin-top:20px !important;
    border: 0px solid white !important;
  }

  .remove-margin{
    margin:0px !important;
  }
  
  /* Modulo */
  .text-modulo{
    color:white !important;
    text-align: center !important;
  }

  #logoModulo{
    display: none;
  }

  .loadingNumero{
    margin-top: 80px !important;
  }

  #BodyModulo{
    background-color: black !important;
  }

  .ButtonAtendiendo{
    background: url("/static/media/boton numero_a.8bf1254a.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 200px;
    height: 200px;
    margin: auto;
  }

  .ButtonLlamar h5{
    margin-top: 90px !important;
    font-family: 'Raleway', sans-serif !important;
  }

  .ButtonAtendiendo h5{
    margin-top: 90px !important;
    font-family: 'Raleway', sans-serif !important;
  }

  .dejarNumero h5{
    margin-top: -30px !important;
  }

  .dejarNumero h5 label{
    font-family: 'Raleway', sans-serif !important;
  }

  .ButtonLlamar{
    background: url("/static/media/boton numero.f9ec535b.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 200px;
    height: 200px;
    margin: auto;
  }

  .ant-row .ant-col-xs-12{
    padding: 0px !important;
  }

  .info-cliente-modulo .ant-card-meta-title{
    margin-top: 12px;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 800;
  }

  .info-sucursal-modulo .ant-card-meta-title{
    margin-top: 4px;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 800;
  }

  .info-sucursal-modulo .ant-card-meta-description{
    color:#C0C0C0 !important;
    font-size: 12px;
    margin-top: -10px;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 800;
  }

  .mod-view .ant-card-meta{
    padding: 10px !important;
  }

  .mod-view .ant-card-head-wrapper{
    height:20px;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 800;
  }

  .mod-view .ant-card-body label{
    margin-top: -28px !important;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 800;
  }

  .mod-view{
    margin-top: -17px;
  }

  .mod-view .info-cliente-modulo .ant-card-meta-description{
    display: inline;
  }

  .mod-view .ant-col-xs-24{
    padding: 0px !important;
  }

  .mod-view .ant-card-body{
    padding: 5px !important;
  }

  .panel-modulo *{
    background-color: black !important;
    color:white !important;
  }

  .mod-view .ant-card-body label{
    color:white !important ;
  }

  .ButtonLlamar > div{
    background: transparent !important;
  }

  .mod-view{
    border:0px solid #C0C0C0 !important;
  }

  .info-sucursal-modulo{
    border:0px solid #C0C0C0 !important;
  }

  .info-cliente-modulo{
    border:0px solid #C0C0C0 !important;
  }

  .div-m-border .ant-card{
    border:0.5px solid #C0C0C0 !important;
  }

  .div-t-border .ant-card{
    border:0.5px solid #C0C0C0 !important;
  }

  .space-modulo{
    background-color: black !important;
    display: none;
  }

  .ant-col-xs-12{
    height: 70px !important;
  }

  .info-sucursal-modulo .ant-card *{
    text-align: left !important;
    align-content: left !important;
    display: block !important;
    justify-content: left !important;
  }

  .info-sucursal-modulo{
    display: inline;
  }

  .info-cliente-modulo{
    display: inline;
  }

  .ant-row{
    padding: 0px !important;
  }

  .ant-card-actions{
    display: none !important;
  }

  h1{
    margin-left:10px !important;
  }

  /* Modulos */
  .modulo-view{
    padding: 0px !important;
  }

  .modulo-view > div{
    padding: 0px !important;
  }

  .mod-view .ant-card-head-title{
    font-size: 12px;
  }

  .mod-view .ant-card-body label{
    font-size: 20px;
  }

  .div-m-border .ant-card-head-title{
    font-size: 11px !important;
  }

  /* Sucursales */
  .home-sucursales .ant-avatar-image{
    width: 50px !important;
    height: 50px !important;
  }

  .home-sucursales .ant-avatar-square{
    width: 50px !important;
    height: 50px !important;
  }

  .home-sucursales{
    padding: 0px !important;
  }

  .home-sucursales .ant-card-meta-title{
    margin-top: 0px;
  }

  .home-sucursales .ant-card-body{
    padding:24px;
  }

  .sucursal-view{
    display: none !important;
  }

  /*Comercios*/
  .home-comercios{
    padding: 0px !important;
  }

  .home-comercios .ant-avatar-image{
  width: 50px !important;
  height: 50px !important;
  }

  .home-comercios .ant-avatar-square{
  width: 50px !important;
  height: 50px !important;
  }

  .home-comercios .ant-card-meta-title{
    margin-top: 10px;
  }

  .logoHeader{
    height: 100px;
  }

  .Rooms-Container{
    height: 87vh;
    overflow-y: hidden;
  }

  .DateStartHeader{
    display: none !important;
  }

  .Filters-Container{
    display: none;
  }

  .Images-Slider-Rooms{
    height: 25vh !important;
  }

  .Container-Room-Type{
    border: 1px solid rgb(228, 228, 228) !important;
    min-height: 540px;
    height:540px;
  }

  .information-container-room{
    height:150px !important;
    border:0px solid white !important;
  }

  .information-container-room-modal{
    border:0px solid white !important;
  }

  .label-info{
    font-size: calc(6px + 1vmin) !important;
  }

  .label-info-promociones{
    font-size: calc(2px + 1vmin) !important;
  }

  .label-subtitle{
    font-size: calc(5px + 1vmin);
  }

  .Box-Container-Room-Types{
    padding-top:10px !important;
  }

  .Filters-Container-Modal{
    height: 92vh;
    overflow-y: scroll;
  }

  .Filters-Button{
    display: block;
  }

  .Images-Slider-Rooms-Modal{
    width:100%;
    height: 160px;
  }

  .discovery-text{
    display: none;
  }

  .buttonPay{
    padding-left: 0px !important;
  }

  .label-subtitle-comments{
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: calc(6px + 1vmin) !important;
    color:black;
  }

  .ant-rate{
    color:#fadb14 !important;
  }
}

